<template>
  <div
    class="certificates-by-fields"
    v-if="hasAllPerms(['certificates.view_certificate', 'fields.view_field'])"
  >
    <page-header
      :title="title" icon="fa fa-certificate" :links="getLinks()"
    ></page-header>
    <div class="sub-header" v-show="initialLoadingDone">
      <b-row>
        <b-col cols="8">
          <h2>
            <counter-label :counter="entities.length" label="famille"></counter-label>
          </h2>
        </b-col>
        <b-col cols="4" class="text-right">
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-input
            id="name"
            v-model="nameFilter"
            placeholder="filtrer par nom de famille"
          >
          </b-form-input>
        </b-col>
        <b-col cols="3">
          <b-form-select
            id="filter-type"
            v-model="selectedFilter"
            @change="onFilterSelected"
            >
            <b-form-select-option
              :value="item.id"
              v-for="item in filterChoices"
              :key="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col>
          <b-checkbox
            id="hasYouthSeance"
            v-model="hasYouthSeance"
            @change="loadElements()"
          >
            Seulement les inscrits à des séances accueils de loisirs
          </b-checkbox>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe">
      <div class="empty-text"  v-if="initialLoadingDone && !isLoading(loadingName) && entities.length === 0">
        Aucune famille
      </div>
      <table
        class="table table-bordered small-text"
        v-if="initialLoadingDone && !isLoading(loadingName) && entities.length"
      >
        <thead>
          <tr>
            <th>Famille</th>
            <th>Année</th>
            <th>Document</th>
            <th>Individu</th>
            <th>État</th>
          </tr>
        </thead>
        <tbody v-for="item in entities" :key="item.id">
          <tr v-for="(cert, $index) in filteredCertificates(item)" :key="cert.id">
            <td v-if="$index === 0" :rowspan="filteredCertificates(item).length">
              <a :href="getLinkToEntityUrl(item.id)" @click.prevent="showEntitySidebar(item.entity)">
                <span class="family-numbers">{{ item.entity.id }}</span> {{ item.entity.name }}
              </a>
            </td>
            <td><span class="badge badge-light">{{ cert.schoolYear.name }}</span></td>
            <td>{{ cert.certificateType.name }}</td>
            <td><span v-if="cert.individual">{{ cert.individual.firstAndLastName() }}</span></td>
            <td>
              <span v-if="cert.certificate">
                <span v-if="cert.certificate.verifiedOn">
                  <span v-if="cert.certificate.refused" class="badge badge-danger">
                    Refusé le {{ cert.certificate.createdOn | dateToString }}
                  </span>
                  <span v-else class="badge badge-success">
                    Vérifié le {{ cert.certificate.createdOn | dateToString }}
                  </span>
                </span>
                <span v-else class="badge badge-primary">
                  A Vérifier
                </span>
              </span>
              <span v-else>
                <span class="badge badge-dark" v-if="cert.certificateType.isRequired">
                  Manquant
                </span>
                <span  class="badge badge-light" v-else>
                  Optionnel
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendApi, openDocument } from '@/utils/http'
import { makeChoice } from '@/types/base'
import { makeCertificate, makeCertificateType } from '@/types/certificates'
import { compareStrings } from '@/utils/sorting'
import { makeEntity, makeIndividual } from '@/types/people'
import { makeSchoolYear } from '@/types/schools'
import router from '@/router'
import { slugify } from '@/utils/strings'

export default {
  name: 'CertificatesByFields',
  props: {
    certificateType: [String, Number],
  },
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'CertificatesByFields',
      certificates: [],
      certificatesCount: 0,
      initialLoadingDone: false,
      selectedCertificateType: makeCertificateType(),
      selectedFilter: 0,
      nameFilter: '',
      filterChoices: [
        makeChoice({ id: 0, name: 'Tous', }),
        makeChoice({ id: 1, name: 'Dossiers complets', }),
        makeChoice({ id: 2, name: 'Dossiers incomplets', })
      ],
      entitiesMap: new Map(),
      hasYouthSeance: false,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    entities() {
      let entities = Array.from(this.entitiesMap.values())
      if (this.nameFilter) {
        const nameFilter = this.nameFilter.toUpperCase()
        entities = entities.filter(elt => elt.name.toUpperCase().indexOf(nameFilter) >= 0)
      }
      if (this.selectedFilter === 1) {
        entities = entities.filter(entity => this.isCompleted(entity))
      } else if (this.selectedFilter === 2) {
        entities = entities.filter(entity => !this.isCompleted(entity))
      }
      return entities.sort(
        (elt1, elt2) => compareStrings(elt1.name, elt2.name)
      )
    },
    title() {
      if (this.selectedCertificateType.id) {
        return 'Justificatifs pour ' + this.selectedCertificateType.name
      } else {
        return 'Justification non supporté'
      }
    },
  },
  mounted() {
    const filtering = +(this.$route.query ? this.$route.query.filter : 2)
    this.onLoaded(filtering)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    filteredCertificates(item) {
      return item.certificates
    },
    isCompleted(item) {
      const certificates = this.filteredCertificates(item)
      const accepted = certificates.filter(
        itm => itm.certificate && (itm.certificate.verifiedOn) && (!itm.certificate.refused)
      )
      return (certificates.length === accepted.length)
    },
    async onLoaded(filtering) {
      await this.loadCertificateType()
      this.selectedFilter = (filtering ? +filtering : 0)
      await this.loadElements()
    },
    onFilterSelected() {
    },
    async loadElements() {
      if (this.selectedCertificateType.forField) {
        this.startLoading(this.loadingName)
        let url = '/certificates/api/certificates-by-fields/' + this.selectedCertificateType.id + '/'
        if (this.hasYouthSeance) {
          url += '?has_youth_inscription=1'
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const entitiesMap = new Map()
          for (const elt of resp.data) {
            let item = null
            if (!entitiesMap.has(elt.entity.id)) {
              item = {
                id: elt.entity.id,
                name: elt.entity.name,
                certificates: [],
                entity: makeEntity(elt.entity),
              }
            } else {
              item = entitiesMap.get(elt.entity.id)
            }
            item.certificates.push(
              {
                id: item.certificates.length + 1,
                schoolYear: makeSchoolYear(elt.school_year),
                individual: elt.individual ? makeIndividual(elt.individual) : null,
                certificateType: makeCertificateType(elt.certificate_type),
                certificate: elt.certificate ? makeCertificate(elt.certificate) : null,
              }
            )
            entitiesMap.set(elt.entity.id, item)
          }
          this.entitiesMap = entitiesMap
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.initialLoadingDone = true
        this.endLoading(this.loadingName)
      }
    },
    async loadCertificateType() {
      try {
        let url = '/certificates/api/certificate-types/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        const certificateTypes = resp.data.filter(
          elt => elt.id === +this.certificateType
        ).map(makeCertificateType).filter(elt => elt.forField)
        if (certificateTypes.length === 1) {
          this.selectedCertificateType = certificateTypes[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinkToEntityUrl(entityId) {
      return router.resolve(this.getLinkToEntity(entityId)).href
    },
    getLinkToEntity(entityId) {
      return { name: 'families-detail', params: { entityId: '' + entityId, }, }
    },
    async excelMe() {
      if (this.selectedCertificateType) {
        const docUrl = '/documents/table-to-excel/<key>/'
        const name = slugify(this.selectedCertificateType.name)
        const docSlug = 'justificatifs-' + name + '-' + moment().format('YYYY-MM-DD-HH-MM-SS')
        const docContent = this.$refs.printMe.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const name = slugify(this.selectedCertificateType.name)
      const docSlug = 'justificatifs-' + name + '-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    viewCertificates() {
      const dest = { name: 'certificates', }
      if (this.selectedCertificateType.id) {
        dest.query = { type: this.selectedCertificateType.id, }
      }
      this.$router.push(dest)
    },
    getLinks() {
      let links = []
      const isNotValid = this.isLoading(this.loadingName) || (this.entities.length === 0)
      links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: isNotValid ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isNotValid ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 3,
          label: 'Liste des justificatifs',
          callback: this.viewCertificates,
          icon: 'fa fa-table',
          cssClass: 'btn-secondary',
        }
      ]
      return links
    },
  },
}
</script>

<style scoped lang="less">
</style>
